import React from 'react';
import './Footer.css';

interface FooterProps {
    className: string
}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
    return (
        <div className={props.className}>
            <div className="column">
                <a className="footeritem" href="https://tools.ietf.org/html/rfc8259" target="blank">json standard</a>

            </div>
            <div className="column">
                <a className="footeritem" href="https://www.ecma-international.org/ecma-262/11.0/index.html" target="blank">ECMAScript</a>
            </div>
            <div className="column">
                <a className="footeritem" href="https://www.typescriptlang.org/" target="blank">typescript</a>
            </div>
            <div className="column">
                <a className="footeritem" href="https://golang.org/" target="blank">Golang</a>
            </div>
        </div>
    );
}

export default Footer;