import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

interface HeaderProps {
    className: string
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
    return (
        <div className={props.className}>
            <div className="leftside">
                <Link to="/" title="jsonDude.com"><h1>Welcome to <b>jsonDude.com</b></h1></Link>
                <h2>A simple json formatter with editing capabilities</h2>
            </div>
            <div className="rightside">
                <Link className="menuitem"
                    to="/" title="JSON formatter">Json Formatter</Link>
                <Link className="menuitem"
                    to="/json-format-info" title="JSON format information">Json Format Info</Link>
                <Link className="menuitem"
                    to="/code-examples/how-to-format-object-into-json"
                    title="Code examples to convert object into json">Code examples</Link>
            </div>
        </div>
    );
}

export default Header;