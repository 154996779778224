import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import './JsonInfo.css';

function JsonInfo() {
    const exampleData1 = {
        title: "Name/value example",
        author: "JsonDude",
        details: {
            year: 2020,
            properties: {
                pages: 1
            }
        }
    }
    const exampleData2 = [
        {
            title: "List item example",
            author: "JsonDude"
        }, {
            title: "List item example 2",
            author: "JsonDude"
        },
        {
            nested_array: ["one", "two"]
        }
    ]
    const exampleData3 = {
        string: "one",
        number: 0,
        object: { object1: "one" },
        array: [0, 1],
        boolean: true,
        null: null,
        array_with_mixed_types: ["1", 1, true, { object: "also" }]
    }
    const example1: string = JSON.stringify(exampleData1, null, 4);
    const example2: string = JSON.stringify(exampleData2, null, 4);
    const example3: string = JSON.stringify(exampleData3, null, 4);
    return (
        <div className="jsoninfo">
             <Helmet>
                <title>Json format info - jsonDude.com</title>
                <meta name="description"
                    content="JSON explained shortly with simple examples to demonstrate how json looks like with different types."/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9CG1C9B28M"></script>
                <script async src="ga.js" type="text/javascript"></script>
             </Helmet>
            <h1>What is JSON?</h1>
            <p>
                JSON (JavaScript Object Notation) is a simple data format that is easy to
                read and write by humans and easy for programs to parse and generate. It supports
                two data structures: name/value pairs and list of values. These data structures
                are widely supported by almost all modern programming languages. You can find some code examples
                for different programming languages from this link: <Link to="/code-examples">Code Examples</Link>.
            </p>

            <h2>Name/value example</h2>
            <p>
                In case of name/value, we simply have a field with a name and value wrapped inside curly braces {'{}'}. 
                Value can contain another name/value or an array.</p>
            <pre>{example1}</pre>

            <h2>List of values example</h2>
            <p>
                When we have a list/array in json, it's defined by using square brackets []. Items in a list can be either lists, or name/values.
            </p>
            <pre>{example2}</pre>

            <h2>Available types for json values</h2>
            <p>
                Value type in json can be a string, a number, an object, an array, a boolean or a null. In below fragment you can find some examples
                how these values look like in json. For more details, take a look at <a href="https://tools.ietf.org/html/rfc8259">https://tools.ietf.org/html/rfc8259</a>
            </p>
            <pre>{example3}</pre>
        </div>
    )
}

export default JsonInfo;