import React from 'react';
import { Helmet } from "react-helmet-async";
import './CodeExamles.css';

interface Person {
    firstname: string,
    lastname: string
}

function CodeExamles() {
    const person: Person = { firstname: 'Json', lastname: 'Dude' };
    const personJson: string = JSON.stringify(person, null, 4);

    const jsExample: string =
        'var person = {\n' +
        '    firstname: \'Json\',\n' +
        '    lastname: \'Dude\'\n' +
        '};\n' +
        'console.log(JSON.stringify(person, null, 4));';

    const tsExample: string =
        'interface Person {\n' +
        '    firstname: string,\n' +
        '    lastname: string\n' +
        '};\n' +
        'const person:Person = {\n' +
        '    firstname: \'Json\',\n' +
        '    lastname: \'Dude\'\n' +
        '};\n' +
        'console.log(JSON.stringify(person, null, 4));';

    const javaExample: string =
        'class Person {\n' +
        '    public final String firstname;\n' +
        '    public final String lastname;\n' +
        '\n' +
        '    Person(final String firstname, final String lastname) {\n' +
        '        this.firstname = firstname;\n' +
        '        this.lastname = lastname;\n' +
        '    }\n' +
        '}\n' +
        'Person person = new Person("Json", "Dude");\n' +
        'ObjectWriter writer = new ObjectMapper().writerWithDefaultPrettyPrinter();\n' +
        'System.out.println(writer.writeValueAsString(person));';

    const goExample: string =
        'type Person struct {\n' +
        '    Firstname string `json:"firstname"`\n' +
        '    Lastname  string `json:"lastname"`\n' +
        '}\n' +
        'person := Person{Firstname: "Json", Lastname: "Dude"}\n' +
        'personBytes, _ := json.MarshalIndent(person, "", "    ")\n' +
        'fmt.Println(string(personBytes))';

    return (
        <div className="codeexamples">
            <Helmet>
                <title>Convert to json code examples - jsonDude.com</title>
                <meta name="description"
                    content="How to convert object to pretty json with javascript, typescript, java and golang."/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9CG1C9B28M"></script>
                <script async src="ga.js" type="text/javascript"></script>
            </Helmet>
            <h1>How to format object into json</h1>
            <p>
                Producing or consuming json is a common task for many programmers. Here
                you can find some basic examples on how to output json from objects with different programming languages. We will
                use a super simple Person object as an example for each language, expected output is shown below.
            </p>
            <pre>{personJson}</pre>
            <div className="caption">Expected json output</div>
            <h2>Javascript</h2>
            <p>
                With javascript the transformation of an object into json is trivial, at least when you don't need to do some custom formatting.
                In case you prefer snake case, here is one library for that: <a href="https://www.npmjs.com/package/snakecase-keys" target="blank">snakecase-keys</a>.
            </p>
            <pre>{jsExample}</pre>
            <h2>Typescript</h2>
            <p>
                Typescript is same as javascript but with possibility to add type information.
            </p>
            <pre>{tsExample}</pre>
            <h2>Java</h2>
            <p>
                This java example uses one the most common library called <a href="https://github.com/FasterXML/jackson-databind" target="blank">Jackson</a> to handle json processing. Another
                common library is <a href="https://github.com/google/gson" target="blank">Google's Gson</a>.
            </p>
            <pre>{javaExample}</pre>
            <h2>Golang</h2>
            <p>
                Golang has built in support to handle json. The choice of formatted and un-formatted json is made by
                choosing between methods Marshal and MarshalIndent.
            </p>
            <pre>{goExample}</pre>
        </div>
    );
}

export default CodeExamles;