import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import './Formatter.css';
import insertText from 'insert-text-textarea';

function Formatter() {
    const example: string = '{"title": "jsondude.com", "description": "simple json formatting"}';
    const placeHolder: string =
        'Put your json here...\n\n' +
        'Shortcuts:\n' +
        '- reformat: alt+ctrl/cmd+k';
    const [input, setInput] = useState(example);
    const [output, setOutput] = useState(example);
    const [validationError, setValidationError] = useState(false);
    const leftRef: React.RefObject<HTMLTextAreaElement> = React.createRef<HTMLTextAreaElement>();

    useEffect(() => {
        try {
            let json = JSON.parse(input)
            let string = JSON.stringify(json, null, 4)
            setOutput(string)
        } catch (error) {
            setValidationError(true)
        }
    }, [input])

    function onChangeInput(e: any) {
        if (e && e.target) {
            setValidationError(false)
            setInput(e.target.value)
        }
    }

    function onChangeOutput(e: any) {
        if (e && e.target) {
            setOutput(e.target.value)
        }
    }

    function handleTab(e: React.KeyboardEvent, ref: React.RefObject<HTMLTextAreaElement>, tabSize: number) {
        if (e.key === 'Tab' && !e.shiftKey) {
            e.preventDefault();
            indent(ref.current!)
        } else if (e.key === 'Tab' && e.shiftKey) {
            e.preventDefault();
            unindent(ref.current!)
        }
        if ((e.key === 'ª' || e.key === 'k') && e.altKey && (e.ctrlKey || e.metaKey)) {
            let json = JSON.parse(input)
            let string = JSON.stringify(json, null, 4)
            setInput(string)
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            indentNewLine(ref.current!);
        }
    }

    function onKeyDownLeft(e: React.KeyboardEvent) {
        handleTab(e, leftRef, 4);
    }

    function indentNewLine(element: HTMLTextAreaElement): void {
        const { selectionStart, value } = element;
        const firstLineStart = value.lastIndexOf('\n', selectionStart - 1) + 1;
        const row: string = value.substring(firstLineStart, selectionStart - 1);
        const indentAmount: number = row.search(/\S|$/)
        setRangeText(element, selectionStart, selectionStart, '\n' + ' '.repeat(indentAmount));
    }

    function indent(element: HTMLTextAreaElement): void {
        const indentContent = '    ';
        const { selectionStart, selectionEnd, value } = element;
        const selectedText = value.slice(selectionStart, selectionEnd);
        const lineBreakCount = /\n/g.exec(selectedText)?.length;

        if (lineBreakCount! > 0) {
            const firstLineStart = value.lastIndexOf('\n', selectionStart - 1) + 1;

            const newSelection = element.value.slice(firstLineStart, selectionEnd - 1);
            const indentedText = newSelection.replace(
                /^|\n/g,
                '$&' + indentContent
            );
            const replacementsCount = indentedText.length - newSelection.length;

            element.setSelectionRange(firstLineStart, selectionEnd - 1);
            insertText(element, indentedText);

            element.setSelectionRange(selectionStart, selectionEnd + replacementsCount);
        } else {
            insertText(element, indentContent);
        }
    }

    function unindent(element: HTMLTextAreaElement): void {
        const { selectionStart, selectionEnd, value } = element;

        if (selectionStart === selectionEnd) {
            const rowStart = value.lastIndexOf('\n', selectionStart - 1) + 1;
            const lineEndIndex = value.indexOf('\n', rowStart + 1);
            const rowEnd = lineEndIndex > 0 ? lineEndIndex : value.length;
            const row = value.slice(rowStart, rowEnd)
            const indentRemovedRow = row.replace(/^(\t|\s{1,4})/, '')
            const delta = (row.length - indentRemovedRow.length);

            if (delta > 0) {
                setRangeText(element, rowStart, rowEnd, indentRemovedRow);

                let offset = Math.max(0, selectionStart - rowStart);
                if (offset < delta) {
                    element.setSelectionRange(selectionStart - offset, selectionStart - offset);
                } else {
                    element.setSelectionRange(selectionStart - delta, selectionStart - delta);
                }
            }

        } else {
            const start = value.lastIndexOf('\n', selectionStart - 1) + 1;
            const endIndex = (selectionEnd < value.length) ? value.lastIndexOf('\n', selectionEnd) : value.length;
            const end = endIndex > 0 ? (endIndex + 1) : selectionEnd;
            let output = '';
            let delta = 0;
            splitLines(value.slice(start, end))?.forEach(row => {
                let indentRemovedRow = row.replace(/^(\t|\s{1,4})/, '')
                output += indentRemovedRow
                delta += (row.length - indentRemovedRow.length);
            })
            if (delta > 0) {
                setRangeText(element, start, end, output);
                element.setSelectionRange(selectionStart, selectionEnd - delta);
            }
        }
    }

    function splitLines(input: string): RegExpMatchArray | null {
        return input.match(/[^\n]*\n|[^\n]+/g);
    }

    function setRangeText(element: HTMLTextAreaElement, start: number, end: number, text: string): void {
        element.setSelectionRange(start, end);
        insertText(element, text);
    }

    return (
        <main>
            <Helmet>
                <title>Json formatter - jsonDude.com</title>
                <meta name="description"
                    content="A simple json formatter and editor to format your json data. Formats json on the fly as you type."/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9CG1C9B28M"></script>
                <script async src="ga.js" type="text/javascript"></script>
            </Helmet>
            <textarea autoFocus
                ref={leftRef}
                className={validationError ? 'left error' : 'left'}
                value={input}
                onChange={onChangeInput}
                onKeyDown={onKeyDownLeft}
                placeholder={placeHolder}></textarea>
            <textarea className="right" value={output} onChange={onChangeOutput}></textarea>
        </main>
    );
}

export default Formatter;
