import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import './Main.css';
import Header from './Header';
import Footer from './Footer';
import Formatter from './Formatter';
import JsonInfo from './JsonInfo';
import CodeExamples from './CodeExamples';

function Main() {

    return (
        <div className="container">
            <HelmetProvider>
                <Router>
                    <Header className="header"></Header>
                    <Switch>
                        <Route path="/json-format-info">
                            <JsonInfo></JsonInfo>
                        </Route>
                        <Route path="/json-format">
                            <JsonInfo></JsonInfo>
                        </Route>
                        <Route path="/code-examples">
                            <CodeExamples></CodeExamples>
                        </Route>
                        <Route path="/code-examples/:title">
                            <CodeExamples></CodeExamples>
                        </Route>
                        <Route path="/">
                            <Formatter></Formatter>
                        </Route>
                    </Switch>
                    <Footer className="footer"></Footer>
                </Router>
            </HelmetProvider>
        </div>
    )
}

export default Main;